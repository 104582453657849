.parallax-container {
    height: 100vh; /* 100% de la altura de la ventana */
    overflow: hidden;
    position: relative;
}

.parallax {
    background-image: url('./assets/img/suarez-bg-3.jpg');
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section {
    /* height: 100vh; */
    background-color: white;
    text-align: center;
    padding: 22.5px;
}

.corners__img {
    position: absolute;
    height: 100.2%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.opacity-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0b2b74ad;
    /* background-color: #234aa73d; */
    display: flex;
}

.uruguay__gif {
    width: 80%;
    max-width: 650px;
}

.page-title__wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.page-title {
    font-size: 50px;
}

.solana__img {
    width: 40px;
    margin-top: 35px;
}

.socials__wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
    margin-top: 60px;
}

.telegram-logo__wrapper {
    width: 70px;
    height: 70px;
}
.telegram-logo__wrapper svg{
    fill: #0038a8;
}

.social-card__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.social-card__text {
    font-weight: bold;
    color: #0038a8;
    margin-top: 15px;
    font-size: 12px;
    letter-spacing: 2px;
}
.social-card__text.raydium-text,
.social-card__text.jupiter-text {
    letter-spacing: 5px;
}
.x-logo__wrapper {
    width: 70px;
    height: 70px;
}
.x-logo__wrapper svg{
    fill: #0038a8;
}
.dexscreener-logo__wrapper {
    width: 70px;
    height: 70px;
}
.dexscreener-logo__wrapper svg{
    fill: #0038a8;
}

.trade-text {
    color: #0038a8;
    font-size: 1.3rem;
    letter-spacing: 0.7em;
    margin-top: 140px;
    margin-bottom: 70px;
    font-weight: bold;
}

.raydium-logo__wrapper {
    width: 70px;
    height: 70px;
}

.raydium__img {
    width: 100%;
    height: 100%;
}

.jupiter-logo__wrapper {
    width: 70px;
    height: 70px; 
}

.jupiter__img {
    width: 100%;
    height: 100%;
}

.contract-address__title {
    color: #022169;
    text-align: center;
    margin-top: 180px;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 15px;
}
.contract-address__text {
    color: #022169;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: 70px;
}

.parallax-container-2 {
    height: 70vh; /* 100% de la altura de la ventana */
    overflow: hidden;
    position: relative;
}

.parallax-2 {
    background-image: url('./assets/img/suarez-bg-2.jpg');
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-2 {
}

.opacity-bg-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0b2b74ad;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auf-logo__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 20px;
}

.auf-logo__img {
    width: 100%;
}


.max-width-800 {
    max-width: 800px;
}

.cavani__gif {
    width: 100%;
}

.section-3 {
    height: 70vh;
    background-color: #022169;
    text-align: center;
    padding: 22.5px;
    display: flex;
    flex-direction: column;
}

.section-3__title {
    color: #fff;
    font-size: 1.4em;
    letter-spacing: 0.5em;
    margin-top: 15px;
}
.section-3__subtitle {
    color: #fff;
    font-size: .7em;
    letter-spacing: 0.2em;
}

.match-time__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 1.2em;
}

.countdown-wrapper {
    color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.countdown-time__wrapper {
    margin-right: 15px;
}
.countdown-time__wrapper:last-child {
    margin-right: 0;
}

.countdown-time__container {
    background-color: #fff;
    color: #022169;
    flex-direction: column;
    width: 60px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 5px;
    border-radius: 3px;
}

.teams__wrapper {
    color: #fff;
    margin-top: auto;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.local-team__wrapper,
.visiting-team__wrapper {
    width: 80px;
}
.local-team__wrapper img,
.visiting-team__wrapper img{
    width: 100%;
}
.team-name {
    margin-top: 20px;
    font-size: 0.4em;
    letter-spacing: 0.2em;
} 
.vs-text {
    margin: auto 25px 0 25px;
    font-size: 24px;
    margin-top: 20px;
}

.bg-section-4 {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
}
.bg-section-4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/img/cavani-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
}

.section-4 {
    width: 100%;
    text-align: center;
    padding: 22.5px;
    display: flex;
    flex-direction: column;
}

.match-table-component__wrapper {
    max-width: 660px;
    margin: 0 auto;
}

.match-table__header {
    display: flex;
}

.cup__img {
    width: 150px;
    padding: 20px;
}

.match-table__title {
    color: #022169;
    font-weight: bold;
    font-size: 1.4em;
    margin: auto 0;
}

.match-table__wrapper {
    margin-bottom: 30px;
}

.page-footer {
    min-height: 40vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-weight: bold;
}

.page-footer-text {
    color: #022169;
}

@media (min-width: 425px) {
    .telegram-logo__wrapper,
    .x-logo__wrapper,
    .dexscreener-logo__wrapper,
    .raydium-logo__wrapper,
    .jupiter-logo__wrapper {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 768px) {
    .contract-address__text {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .parallax,
    .parallax-2 {
        background-attachment: scroll;
        background-size: cover;
        background-position: center;
    }
}


/* animations */
/* zoom on hover animation */
.zoom-on-hover {
	transition: transform 0.3s ease;
}

.zoom-on-hover:hover {
	transform: scale(1.2);
}