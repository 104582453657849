.table-container {
    overflow-x: auto;
  }
  
  .match-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 20px 0;
    font-size: 14px;
  }
  
  .match-table th,
  .match-table td {
    padding: 12px 15px;
  }
  
  .match-table thead th {
    background-color: #333;
    color: #ffffff;
  }
  
  .match-table tbody td {
    background-color: #444;
    color: #ffffff;
  }
  
  .match-table tbody tr:nth-child(even) {
    background-color: #555;
  }
  
  .flag {
    margin-right: 10px;
  }
  
  @media (max-width: 767px) {
    .match-table th,
    .match-table td {
      padding: 12px 9px;
    }
  }

  @media (max-width: 768px) {
    .match-table {
      font-size: 12px;
    }
  }